import { useCallback } from 'react';

// Custom hook to prevent default behavior of an event
const usePreventDefault = (): ((event: React.SyntheticEvent) => void) => {
  return useCallback((event: React.SyntheticEvent) => {
    event.preventDefault();
  }, []);
};

export default usePreventDefault;
